import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import {
  OrderBy,
  RoleEnum,
  UserSortableFields,
  useUsersQuery,
} from 'Apollo/graphql';
import {
  areOptionsEqualById,
  filterAll,
  getOptionFieldUsername,
} from 'Utils/helpers';
import { useThrottleValue, } from 'Tools/useThrottleValue';

export const useUserSearchOptions = () => {
  const [searchState, setSearchState,] = useState<string>('');
  const throttleValue = useThrottleValue(searchState);
  const { t, } = useTranslation();

  const handleSearchChange = (e: unknown, newSearchValue: string) =>
    setSearchState(newSearchValue);

  const { loading, error, data, previousData, } = useUsersQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        role: {
          in: [
            RoleEnum.CzBrandAmbassador,
            RoleEnum.PlBrandAmbassador,
            RoleEnum.PosCz,
            RoleEnum.PosSeller,
            RoleEnum.PosManager,
            RoleEnum.IndependentPosOwner,
            RoleEnum.CzIndependentPosOwner,
            RoleEnum.PlIndependentPosOwner,
          ],
        },
        username: {
          contains: throttleValue,
        },
      },
      sort: {
        field: UserSortableFields.Id,
        order: OrderBy.Asc,
      },
      pagination: {
        offset: 0,
        limit: 500,
      },
    },
  });

  const options = data?.users.data || [];
  const previousOptions = previousData?.users.data || [];

  return {
    loading,
    error,
    options: loading && options.length < 1 ? previousOptions : options,
    searchState,
    getOptionLabel: getOptionFieldUsername,
    isOptionEqualToValue: areOptionsEqualById,
    onInputChange: handleSearchChange,
    filterOptions: filterAll,
    noOptionsText:
      !loading && throttleValue === ''
        ? t('common:select.typeToSearch')
        : undefined,
  };
};
